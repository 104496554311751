import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  { path: "", loadChildren: "./pages/login/login.module#LoginPageModule" },
  { path: "", loadChildren: "./tabs/tabs.module#TabsPageModule" },
  {
    path: "reset-password/:uid/:token",
    loadChildren:
      "./pages/reset-password/reset-password.module#ResetPasswordPageModule",
  },
  {
    path: "**",
    loadChildren: "./pages/not-found/not-found.module#NotFoundPageModule",
  },
  {
    path: "inventory",
    loadChildren: "./pages/inventory/inventory.module#InventoryPageModule",
  },
  { path: 'map', loadChildren: './pages/map/map.module#MapPageModule' },


  { path: 'Offtopic', loadChildren: './pages/offtopic/offtopic.module#OfftopicPageModule' },
  // { path: 'pending', loadChildren: './pages/wtb/new/pending/pending.module#PendingPageModule' },
  // { path: 'search', loadChildren: './pages/search/search.module#SearchPageModule', canActivate: [AuthGuard] },
  // { path: 'profile', loadChildren: './settings/profile/profile.module#ProfilePageModule', canActivate: [AuthGuard] },
  // { path: 'company', loadChildren: './settings/company/company.module#CompanyPageModule', canActivate: [AuthGuard] },
  // { path: 'new-wtb', loadChildren: './pages/new/new-wtb/new-wtb.module#NewWtbPageModule', canActivate: [AuthGuard] },
  // { path: 'new-list', loadChildren: './pages/new/new-list/new-list.module#NewListPageModule', canActivate: [AuthGuard] },
  // { path: 'new-preview', loadChildren: './pages/new/new-preview/new-preview.module#NewPreviewPageModule', canActivate: [AuthGuard] },
  // { path: 'details', loadChildren: './pages/details/details.module#DetailsPageModule', canActivate: [AuthGuard] },
  // { path: 'change-password', loadChildren: './settings/profile/change-password/change-password.module#ChangePasswordPageModule' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
