import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../app-settings';

import { Storage } from '@ionic/storage';
import { NavController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AccountService {


  private isUserLoggedIn = false;
  private isAppOpened = false;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
  ) { }

  setIsUserLoggedIn(isLoggedIn: boolean): void {
    this.isUserLoggedIn = isLoggedIn;
  }

  getIsUserLoggedIn(): boolean {
    return this.isUserLoggedIn;
  }

  setIsAppOpened(isOpened: boolean): void {
    this.isAppOpened = isOpened;
  }

  getIsAppOpened(): boolean {
    return this.isAppOpened;
  }


  public login(mail, password, keepSignedIn = false) {

    let promise = new Promise((resolve, reject) => {

      this.http.post(AppSettings.SERVER_URL + "accounts/login/", { 'email': mail, 'password': password, 'keep_signed_in': keepSignedIn },)
        .toPromise()
        .then(response => {
          this.storage.set('session', { 'mail': mail, 'key': response['key'] })
            .then(
              () => resolve(response),
              error => reject(error)
            )
          resolve(response);
        })
        .catch(error => reject(error))
    });

    return promise;
  }

  public checkLogin() {
    return this.storage.get('session')
      .then(session => {
        return !!session['key'];
      }).catch(() => {
        return false;
      });
  }

  public logout() {
    let promise = new Promise((resolve, reject) => {

      this.storage.get('session')
        .then(session => {

          if (!session) {
            reject("No session open");
          }

          let key = session['key']

          const headers = new HttpHeaders({
            'Authorization': 'Token ' + key,
            'accept': 'application/json'
          });

          const options = { headers: headers }

          this.storage.remove('session')
          this.http.post(AppSettings.SERVER_URL + 'accounts/logout/', null, options)
            .toPromise()
            .then(response => {
              resolve(response);
            })
            .catch(error => reject(error));
        });
    });

    return promise;
  }

  public forgottenPassword(mail) {
    let promise = new Promise((resolve, reject) => {

      this.http.post(AppSettings.SERVER_URL + 'accounts/password/reset/', { 'email': mail })
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });

    return promise;
  }

  // Profile
  public myProfile(profile = null, save = false) {

    let promise = new Promise((resolve, reject) => {

      this.storage.get('session')
        .then(session => {
          let key = session['key']
          const headers = new HttpHeaders({
            'Authorization': 'Token ' + key,
            'accept': 'application/json'
          });

          const options = { headers: headers }

          let url = AppSettings.SERVER_URL + 'accounts/mine/';
          if (profile && save) {
            this.http.put(url, profile, options)
              .toPromise()
              .then(response => {
                resolve(response);
              })
              .catch(error => reject(error));
          } else {
            this.http.get(url, options)
              .toPromise()
              .then(response => {
                resolve(response);
              })
              .catch(error => reject(error));
          }
        })
    });
    return promise;
  }

  // Company
  public myCompany(company = null, save = false) {

    let promise = new Promise((resolve, reject) => {

      this.storage.get('session')
        .then(session => {
          let key = session['key']

          const headers = new HttpHeaders({
            'Authorization': 'Token ' + key,
            'accept': 'application/json'
          });

          const options = { headers: headers }

          let url = AppSettings.SERVER_URL + 'companies/mine/';
          if (company && save) {
            this.http.put(url, company, options)
              .toPromise()
              .then(response => {
                resolve(response);
              })
              .catch(error => reject(error));
          } else {
            this.http.get(url, options)
              .toPromise()
              .then(response => {
                resolve(response);
              })
              .catch(error => reject(error));
          }
        })
    });
    return promise;
  }

  public changePassword(oldPassword, newPassword1, newPassword2) {

    let t = this;

    let promise = new Promise(async (resolve, reject) => {

      this.storage.get('session')
        .then(session => {

          let key = session['key']

          const headers = new HttpHeaders({
            'Authorization': 'Token ' + key,
            'accept': 'application/json'
          });

          let data = {
            'old_password': oldPassword,
            'new_password1': newPassword1,
            'new_password2': newPassword2
          }

          const options = { headers: headers }

          t.http.post(AppSettings.SERVER_URL + "accounts/password/change/", data, options)
            .toPromise()
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              console.log(error)
              reject(error)
            });
        });
    });

    return promise;
  }

  public resetPassword(uid, key, newPassword1, newPassword2) {
    let promise = new Promise((resolve, reject) => {

      let data = {
        'uid': uid,
        'token': key,
        'new_password1': newPassword1,
        'new_password2': newPassword2
      }

      this.http.post(AppSettings.SERVER_URL + "accounts/password/reset/confirm/" + uid + "/" + key + "/", data)
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }

  public getSessionKey() {
    let promise = new Promise((resolve, reject) => {
      this.storage.get('session')
        .then(async (session) => {
          if (!session || !session['key']) {
            this.navCtrl.navigateRoot("");
            let toast = await this.toastCtrl.create({
              message: "You have been disconnected",
              duration: 2000,
              showCloseButton: true
            })
            toast.present()
            reject()
          } else {
            let key = session['key']
            resolve(key)
          }
        });
    });
    return promise;
  }
}
