import { Injectable } from "@angular/core";
//import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ToastController, NavController } from "@ionic/angular";
import { AccountService } from "../api/account.service";
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastCtrl: ToastController,
    private accountService: AccountService,
    private navCtrl: NavController
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          return event;
        },
        async (error) => {
          //Redirect to login and display an error toast if received an ERR_CONNECTION_REFUSED
          if (error instanceof HttpErrorResponse && error.status === 0) {
            this.accountService.logout().catch(async () => {
              this.navCtrl.navigateRoot("");

              this.toastCtrl.dismiss();

              let toast = await this.toastCtrl.create({
                message:
                  "Failed to connect to the server. Please try again later.",
                duration: 3000,
                position: "bottom",
                showCloseButton: true,
                color: 'medium',
              });
              toast.present();
            });
          }

          //Redirect to login if token is invalid
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.accountService.logout().catch(async () => {
              this.navCtrl.navigateRoot("");
              this.toastCtrl.dismiss();
            });
          }

          //Redirect to home page and display an error toast if received a 403 (Forbidden)
          if (error instanceof HttpErrorResponse && error.status === 403) {
            this.navCtrl.navigateRoot("");

            this.toastCtrl.dismiss();

            let toast = await this.toastCtrl.create({
              message: "You don't have the right to access this resource.",
              duration: 3000,
              position: "bottom",
              showCloseButton: true,
              color: 'warning',
            });
            toast.present();
          }
        }
      )
    );
  }
}
