import { Component, ViewChildren, QueryList } from "@angular/core";
import { NotificationsService } from "src/app/api/notifications.service";
import { AlertController } from '@ionic/angular';
import { AccountService } from './api/account.service';


import {
  Platform,
  ModalController,
  IonRouterOutlet,
  MenuController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppSettings } from "./app-settings";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./menu.page.scss"],
})
export class AppComponent {
  pages = [
    {
      name: "All Posts",
      url: "/tabs/tab1",
      icon: "home",
      children: [],
      open: false,
    },
    {
      name: "My Posts",
      url: "/tabs/tab2",
      icon: "paper",
      children: [],
      open: false,
    },
    {
      name: "New Post",
      url: "/tabs/pending",
      icon: "add-circle-outline",
      open: true,
      children: [
        {
          name: "WTB",
          nameChip: {
            color: "secondary",
            text: "Network",
          },
          url: "/tabs/pending/WTB/NETWORK",
          img: "ico-network.png",
        },
        {
          name: "RFQ",
          nameChip: {
            color: "secondary",
            text: "Network",
          },
          url: "/tabs/pending/RFQ/NETWORK",
          img: "ico-network-border.png",
        },
        {
          name: "WTS",
          nameChip: {
            color: "secondary",
            text: "Network",
          },
          url: "/tabs/pending/WTS/NETWORK",
          img: "ico-network-border-end.png",
        },
        {
          name: "WTB ",
          nameChip: {
            color: "warning",
            text: "Server",
          },
          url: "/tabs/pending/WTB/SERVER",
          img: "ico-server.png",
        },
        {
          name: "RFQ ",
          nameChip: {
            color: "warning",
            text: "Server",
          },
          url: "/tabs/pending/RFQ/SERVER",
          img: "ico-server-border.png",
        },
        {
          name: "WTS ",
          nameChip: {
            color: "warning",
            text: "Server",
          },
          url: "/tabs/pending/WTS/SERVER",
          img: "ico-server-border-end.png",
        },
      ],
    },
    {
      name: "OT",
      url: "/tabs/Offtopic",
      icon: "mail",
      children: [],
      open: false,
    },
    {
      name: "My Inventory",
      url: "/tabs/inventory",
      icon: "filing",
      children: [],
      open: false,
    },
    {
      name: "Map",
      url: "/tabs/map",
      icon: "map",
      children: [],
      open: false,
    },
    {
      name: "Analytics",
      url: "/tabs/analytics",
      icon: "analytics",
      children: [],
      open: false,
    },
    {
      name: "My Profile",
      url: "/tabs/tab4",
      icon: "person",
      children: [],
      open: false,
    },

    {
      name: "Notifications",
      url: "/tabs/notifications",
      icon: "notifications",
      children: [],
      open: false,
    },

  ];

  isWeb: boolean;
  isMenuOpen: boolean;
  public selectedItem: string;
  public nbNotification: any;
  public msg: any;
  public notifications: any[] = [];
  private ispopupDisplayed = false;
  public IsLoginPopUp = true;
  public isOtChecked = false;



  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private router: Router,
    private alertController: AlertController,
    public notificationsService: NotificationsService,
    private accountService: AccountService,
  ) {
    // AppSettings.IS_WEB = !platform.is('mobile')
    this.isWeb = AppSettings.IS_WEB;
    this.menuCtrl.enable(AppSettings.IS_WEB);
    this.isMenuOpen = AppSettings.IS_WEB;
    this.initializeApp();
    let t = this;
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((navEnd: NavigationEnd) => {
        t.pages.some((page) => {
          if (page["url"] === navEnd.urlAfterRedirects) {
            t.selectedItem = page["name"];
          }

          page.children.some((child) => {
            if (child["url"] === navEnd.urlAfterRedirects) {
              t.selectedItem = child["name"];
              return true;
            }
          });
          return t.selectedItem !== undefined;
        });
      });
  }


  ngOnInit(): void {
    this.accountService.setIsAppOpened(true);

    if (!this.ispopupDisplayed) {
      this.getNotifications();
    }
  }




  getNumberNotifications() {
    let t = this;
    this.notificationsService.CountNotifications().then((response) => {
      t.nbNotification = response["nb_notifications"];
      this.presentAlert(t.nbNotification);
    });
  }

  getNotifications() {
    let t = this;
    let lastNotificationtitle;
    this.notificationsService.getNotifications().then((response) => {
      if (response['results']) {
        t.notifications = response["results"];
        let lastNotificationIndex = t.notifications.length - 1;
        lastNotificationtitle = t.notifications[lastNotificationIndex].title;
        t.msg = lastNotificationtitle;
        this.getNumberNotifications();
      }
    });
    return lastNotificationtitle;
  }

  async presentAlert(notificationsCount: number) {
    if ((this.accountService.getIsUserLoggedIn() && this.accountService.getIsAppOpened())) {
      // Display notification
      if (notificationsCount >= 1) {
        const alert = await this.alertController.create({
          header: 'you have a Notification',
          message: '' + this.msg,
          buttons: [
            {
              text: 'OK',
              handler: () => {
                this.router.navigate(['/tabs/notifications'])
              },
            }
          ]
        });
        this.IsLoginPopUp = false;
        alert.backdropDismiss = false;
        await alert.present();
      }
    }
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
    });
  }
}
