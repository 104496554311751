import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicStorageModule } from "@ionic/storage";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpErrorInterceptor } from "./interceptor/httperror.interceptor";
import { EmailComposer } from "@ionic-native/email-composer/ngx";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
import { IonicSelectableModule } from "ionic-selectable";
import { AppSettings } from "./app-settings";

export function AppConfigLoader() {
  return () => {
    return Promise.resolve().then(() => {
      if (AppSettings.IS_WEB) {
        if (window.location.origin == "https://uneda-dev.azurewebsites.net/") {
          AppSettings.SERVER_URL = 'https://uneda-api-dev.azurewebsites.net/api/';
        } else if (window.location.origin == "https://app.uneda.com") {
          AppSettings.SERVER_URL = 'https://www.uneda.com/api/'
        }

      }
    });
  }
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    IonicSelectableModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    CallNumber,
    EmailComposer,
    Geolocation,
    AppVersion,
    InAppBrowser,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [],
      useFactory: AppConfigLoader
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
