import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../app-settings";

import { Storage } from "@ionic/storage";
import { AccountService } from "./account.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private accountService: AccountService
  ) {}

  public getNotifications() {
    let promise = new Promise((resolve, reject) => {
      this.storage.get("session").then((session) => {
        let key = session["key"];

        const headers = new HttpHeaders({
          Authorization: "Token " + key,
          accept: "application/json",
        });

        const options = { headers: headers };

        let url = AppSettings.SERVER_URL + "accounts/notifications/";

        this.http
          .get(url, options)
          .toPromise()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    });
    return promise;
  }

  public CountNotifications() {
    let promise = new Promise((resolve, reject) => {
      this.storage.get("session").then((session) => {
        let key = session["key"];

        const headers = new HttpHeaders({
          Authorization: "Token " + key,
          accept: "application/json",
        });

        const options = { headers: headers };

        let url = AppSettings.SERVER_URL + "accounts/notifications/all";

        this.http
          .get(url, options)
          .toPromise()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    });
    return promise;
  }
}
